@font-face {
    src: url('../fonts/tiempos-text-regular.woff2') format('woff2');
    font-family: 'Tiempos';
    font-weight: 400;
    font-display: swap;
}

@font-face {
    src: url('../fonts/tiempos-text-medium.woff2') format('woff2');
    font-family: 'Tiempos';
    font-weight: 500;
    font-display: fallback;
}

@font-face {
    src: url('../fonts/tiempos-text-regular-italic.woff2') format('woff2');
    font-family: 'Tiempos';
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    src: url('../fonts/tiempos-text-medium-italic.woff2') format('woff2');
    font-family: 'Tiempos';
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
}
