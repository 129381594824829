@use '../utilities/scale';
@use '../utilities/styles';

footer {
    padding-bottom: 0;

    // the correct background color gets reapplied by a scheme-dark class inside the scilog footer
    &.scheme-dark {
        background-color: transparent;
    }
}

.footer-top {
    // px to save some screen space for users who only increase the base font size
    margin-bottom: 16px;
    border-bottom: styles.border-thin(1);
    padding: 24px 0;
    text-align: center;

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.footer-content,
.footer-pages,
.footer-top {
    color: #fff;
}

.footer-pages {
    padding-bottom: scale.px-to-rem(12);
}

.footer-claim.claim {
    @include media-breakpoint-up(md) {
        margin-bottom: 0 !important;
        padding: 0;
    }
}

.footer-scilog-s {
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        margin: auto 0;
    }
}

div.footer-form {
    @include media-breakpoint-down(md) {
        border-bottom: none;
        padding-bottom: 0;
    }
}

@include media-breakpoint-up(sm) {
    .footer-scilog-s img {
        width: scale.fluid-size(54, 84, 576, 1642);
        height: auto;
    }
}
