@charset "utf-8";

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

@import 'scilog/fonts';
@import 'theme';
@import 'scilog/theme';
@import 'scilog/footer';

.logo {
    mask-image: url('../logos/logo_scilog.svg');
}

.content {
    p, .card-subtitle, .card-text {
        font-family: var(--running-text-font-family);
    }

    .teaser, .quote {
        font-family: var(--font-family);
    }
}
